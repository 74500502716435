import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { store, fetch, destroy } from "../utils/httpUtil";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { QUERY_KEY } from "../utils/queryKeys";
import { CLOUD_PROVIDER_AWS } from "../utils/constants";
import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";

const getAWSCloudFormationURL = async () => {
  try {
    const response = await fetch("cloud/cloudformation/");
    return response.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const createAWSCloudFormationStack = async (data) => {
  try {
    const response = await store("cloud/aws-creds/", data);
    return response;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getAWSCloudFormation = async () => {
  try {
    const response = await fetch("cloud/aws-creds/");
    return response?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const deleteAWSCloudFormationStack = async (stack_id) => {
  try {
    const response = await destroy(`cloud/aws-creds/${stack_id}/`);
    return response?.data;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

export const useCloudFormation = () => {
  const queryClient = useQueryClient();

  const getCloudFormationURLQuery = useQuery({
    queryKey: [QUERY_KEY.AWS_CLOUD_FORMATION_URL],
    queryFn: getAWSCloudFormationURL,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 2,
    enabled: false,
  });

  const createAWSCloudFormationStackMutation = useMutation({
    mutationFn: (payload) => createAWSCloudFormationStack(payload),
    onSuccess: (data) => {
      const response = data.data;
      queryClient.setQueryData([QUERY_KEY.AWS_CLOUD_FORMATION], (oldData) => {
        if (oldData) {
          return [...oldData, response];
        } else {
          return [response];
        }
      });

      // This will update the cache for api that has used on dashboard and recommendations page
      queryClient.setQueryData(
        [QUERY_KEY.CLOUD_KEYS, CLOUD_PROVIDER_AWS],
        (oldData) => {
          if (oldData) {
            return [
              ...oldData,
              { id: response.id, key_label: response.key_label },
            ];
          }
          return [{ id: response.id, key_label: response.key_label }];
        }
      );

      // This will invalidate the cache for api that has used on dashboard and recommendations page
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] !== QUERY_KEY.AWS_CLOUD_FORMATION &&
            query.queryKey[0] !== QUERY_KEY.CLOUD_KEYS
          );
        },
      });
    },
    onError: (error) => {
      return error;
    },
  });

  const getAWSCloudFormationQuery = useQuery({
    queryKey: [QUERY_KEY.AWS_CLOUD_FORMATION],
    queryFn: getAWSCloudFormation,
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  const deleteAWSCloudFormationStackMutation = useMutation({
    mutationFn: (payload) => deleteAWSCloudFormationStack(payload),
    onSuccess: (_, variables) => {
      const stackId = variables;
      queryClient.setQueryData([QUERY_KEY.AWS_CLOUD_FORMATION], (oldData) => {
        if (oldData) {
          return oldData.filter((item) => item.id !== stackId);
        } else {
          return [];
        }
      });

      // This will update the cache for api that has used on dashboard and recommendations page
      queryClient.setQueryData(
        [QUERY_KEY.CLOUD_KEYS, CLOUD_PROVIDER_AWS],
        (oldData) => {
          if (oldData) {
            return oldData.filter((item) => item.id !== stackId);
          }
          return [];
        }
      );

      // This will invalidate the cache for api that has used on dashboard and recommendations page
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] !== QUERY_KEY.AWS_CLOUD_FORMATION &&
            query.queryKey[0] !== QUERY_KEY.CLOUD_KEYS
          );
        },
      });
    },
    onError: (error) => {
      return error;
    },
  });

  return {
    createAWSCloudFormationStackMutation,
    getAWSCloudFormationQuery,
    deleteAWSCloudFormationStackMutation,
    getCloudFormationURLQuery,
  };
};
