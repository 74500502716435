import React from "react";
import { Box, Button } from "@material-ui/core";
import MarkDown from "react-markdown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import rehypeSlug from "rehype-slug";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const Copy = ({ children }) => {
  const codeContent = children?.props?.children?.toString() || "";

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(codeContent);
      alert("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        marginBottom: "16px",
        "& pre": {
          backgroundColor: "gray",
          color: "white",
          border: "1px solid #ddd",
          padding: "12px",
          borderRadius: "4px",
          overflow: "auto",
        },
        "& button": {
          position: "absolute",
          top: "8px",
          right: "8px",
          backgroundColor: "#fff",
          minWidth: "32px",
        },
      }}
    >
      <Button
        variant="contained"
        size="small"
        startIcon={<ContentCopyIcon />}
        onClick={handleCopy}
      >
        Copy
      </Button>
      <pre>{children}</pre>
    </Box>
  );
};

const DocViewer = ({ markDownContent }) => {
  return (
    <MarkDown
      components={{
        pre: ({ children }) => <Copy>{children}</Copy>,
        h1: ({ node, ...props }) => {
          const id = node.children[0]?.value
            ?.toString()
            .toLowerCase()
            .replace(/\s+/g, "-");
          return <h1 id={id} {...props} />;
        },
        h2: ({ node, ...props }) => {
          const id = node.children[0]?.value
            ?.toString()
            .toLowerCase()
            .replace(/\s+/g, "-");
          return <h2 id={id} {...props} />;
        },
        h3: ({ node, ...props }) => {
          const id = node.children[0]?.value
            ?.toString()
            .toLowerCase()
            .replace(/\s+/g, "-");
          return <h3 id={id} {...props} />;
        },
        img: ({ node, ...props }) => {
          return (
            <Box
              style={{
                padding: "10px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                {...props}
                component="img"
                src={props.src}
                alt={props.alt || ""}
                style={{
                  maxWidth: "100%",
                  maxHeight: "500px",
                  width: "auto",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </Box>
          );
        },
      }}
      rehypePlugins={[rehypeSlug, rehypeRaw]}
      remarkPlugins={[remarkGfm]}
    >
      {markDownContent?.toString()}
    </MarkDown>
  );
};

export default DocViewer;
