import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, List, ListItem, Typography } from "@mui/material";
import { useScrollToIdOnSamePageHook } from "../../hooks/useScrollToIdOnSamePageHook";

const HeadingsList = ({ headings, selectedFile, loadingContent }) => {
  const reactRouterDomHistory = useHistory();
  const location = useLocation();
  const [selectedHeading, setSelectedHeading] = useState(
    location.hash?.split("#")[1]
  );

  const { setIsHeadingClicked } = useScrollToIdOnSamePageHook(
    headings.map((heading) => heading.id),
    setSelectedHeading,
    [headings]
  );

  const scrollToHeading = useCallback(
    (heading) => {
      setIsHeadingClicked(true);
      const HEADER_HEIGHT = 60;
      const id = heading
        .replace(/#/g, "")
        .replace(/`/g, "")
        .replace(/\*+/g, "")
        .replace(/\\+/g, "")
        .trim()
        .replace(/\s+/g, "-")
        .replace(/\./g, "")
        .replace(/&/g, "")
        .replace(/!/g, "")
        .toLowerCase();
      const header = document.getElementById(id);
      if (!header) return;

      setSelectedHeading(id);

      if (heading !== decodeURIComponent(location.hash)) {
        reactRouterDomHistory.push(
          `/resources/docs/${selectedFile?.split(".")[0]}#${heading}`
        );
      }

      const elementPosition =
        header.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - HEADER_HEIGHT;

      window.scrollTo({
        behavior: "instant",
        top: offsetPosition,
      });
      setTimeout(() => {
        setIsHeadingClicked(false);
      }, 100);
    },
    [setIsHeadingClicked, location.hash, reactRouterDomHistory, selectedFile]
  );

  useEffect(() => {
    if (location.hash && !loadingContent) {
      scrollToHeading(decodeURIComponent(location.hash));
    }
  }, [location.hash, loadingContent, scrollToHeading]);

  return (
    <Box
      sx={{
        display: { desktop: "block", mobile: "none" },
        position: "sticky",
        top: "70px",
      }}
    >
      <List>
        {headings.map((heading, index) => {
          return (
            <ListItem
              key={index}
              onClick={() => scrollToHeading(heading.id)}
              sx={{
                cursor: "pointer",
                borderTopRightRadius: "5px",
                borderBottomRightRadius: "5px",
                borderLeft:
                  selectedHeading === heading.id
                    ? "1px solid #39b2ec"
                    : "1px solid rgb(170, 170, 170)",
                color: selectedHeading === heading.id ? "#39b2ec" : "#4a4a4a",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  background: "#0074d040",
                },
              }}
            >
              <Typography variant="subtitle2">
                {heading.heading.replace(/#/g, "").trim()}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default HeadingsList;
