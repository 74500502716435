import { useEffect, useState } from "react";

export const onScrollEventListener = (
  listOfIds,
  setClosestDivToScreenTopId
) => {
  const top = listOfIds.map((id) => {
    return {
      id,
      top: Math.abs(document.getElementById(id)?.getBoundingClientRect().top),
    };
  });

  const idOfNearestElement = top.reduce((prev, current) => {
    return prev.top < current.top ? prev : current;
  }).id;

  setClosestDivToScreenTopId(idOfNearestElement);
};

export const useScrollToIdOnSamePageHook = (
  listOfIds,
  setClosestDivToScreenTopId,
  dependencyArray = []
) => {
  const [isHeadingClicked, setIsHeadingClicked] = useState(false);
  useEffect(() => {
    if (isHeadingClicked) return;
    const scrollEventListener = () => {
      onScrollEventListener(listOfIds, setClosestDivToScreenTopId);
    };
    window.addEventListener("scroll", scrollEventListener);
    return () => {
      window.removeEventListener("scroll", scrollEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencyArray, listOfIds, setClosestDivToScreenTopId]);

  return { setIsHeadingClicked };
};
