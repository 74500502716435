import React from "react";
import { Box, Typography } from "@material-ui/core";

import HalfCloudRight from "../../assets/half_cloud_right_wider.svg";
import HalfCloudLeft from "../../assets/half_cloud_left_wider.svg";

export const LeftPane = () => {
  return (
    <Box style={{ position: "relative" }}>
      <Box
        component="img"
        src={HalfCloudLeft}
        style={{ position: "absolute", right: "0", top: "20px", padding: 0 }}
      />
      <Box
        style={{
          maxWidth: "70%",
          margin: "0 auto",
          display: "grid",
          placeItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        <Box>
          <Typography
            variant="h3"
            style={{
              background: "linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              webkitTextFillColor: "transparent",
            }}
          >
            Optimizing costs
          </Typography>
          <Typography variant="h4" style={{ color: "black" }}>
            isn't about spending less; it's about spending smarter to unlock
            greater value and sustainable success.
          </Typography>
        </Box>
      </Box>
      <Box
        component="img"
        src={HalfCloudRight}
        style={{ position: "absolute", left: "0", bottom: "20px", padding: 0 }}
      />
    </Box>
  );
};
