import AwsStep1 from "../assets/add-token-instructions/aws/1.png";
import AwsStep2 from "../assets/add-token-instructions/aws/2.png";
import AwsStep3 from "../assets/add-token-instructions/aws/3.png";
import AwsStep4 from "../assets/add-token-instructions/aws/4.png";
import AwsStep5 from "../assets/add-token-instructions/aws/5.png";
import AwsStep6 from "../assets/add-token-instructions/aws/6.png";
import AwsStep7 from "../assets/add-token-instructions/aws/7.png";
import AwsStep8 from "../assets/add-token-instructions/aws/8.png";
import AwsStep9 from "../assets/add-token-instructions/aws/9.png";
import AwsStep10 from "../assets/add-token-instructions/aws/10.png";
import AwsStep11 from "../assets/add-token-instructions/aws/11.png";
import AwsStep12 from "../assets/add-token-instructions/aws/12.png";
import AwsStep13 from "../assets/add-token-instructions/aws/13.png";
import AwsStep14 from "../assets/add-token-instructions/aws/14.png";
import AwsStep15 from "../assets/add-token-instructions/aws/15.png";

import AzureStep1 from "../assets/add-token-instructions/azure/1.png";
import AzureStep2 from "../assets/add-token-instructions/azure/2.png";
import AzureStep3 from "../assets/add-token-instructions/azure/3.png";
import AzureStep4 from "../assets/add-token-instructions/azure/4.png";
import AzureStep5 from "../assets/add-token-instructions/azure/5.png";
import AzureStep6 from "../assets/add-token-instructions/azure/6.png";
import AzureStep7 from "../assets/add-token-instructions/azure/7.png";
import AzureStep8 from "../assets/add-token-instructions/azure/8.png";
import AzureStep9 from "../assets/add-token-instructions/azure/9.png";
import AzureStep10 from "../assets/add-token-instructions/azure/10.png";
import AzureStep11 from "../assets/add-token-instructions/azure/11.png";
import AzureStep12 from "../assets/add-token-instructions/azure/12.png";
import AzureStep13 from "../assets/add-token-instructions/azure/13.png";
import AzureStep14 from "../assets/add-token-instructions/azure/14.png";
import AzureStep15 from "../assets/add-token-instructions/azure/15.png";
import AzureStep16 from "../assets/add-token-instructions/azure/16.png";
import AzureStep17 from "../assets/add-token-instructions/azure/17.png";
import AzureStep18 from "../assets/add-token-instructions/azure/18.png";
import AzureStep19 from "../assets/add-token-instructions/azure/19.png";
import AzureStep20 from "../assets/add-token-instructions/azure/20.png";
import AzureStep21 from "../assets/add-token-instructions/azure/21.png";
import AzureStep22 from "../assets/add-token-instructions/azure/22.png";
import AzureStep23 from "../assets/add-token-instructions/azure/23.png";
import AzureStep24 from "../assets/add-token-instructions/azure/24.png";
import AzureStep25 from "../assets/add-token-instructions/azure/25.png";
import AzureStep26 from "../assets/add-token-instructions/azure/26.png";
import AzureStep27 from "../assets/add-token-instructions/azure/27.png";
import AzureStep28 from "../assets/add-token-instructions/azure/28.png";
import AzureStep29 from "../assets/add-token-instructions/azure/29.png";
import AzureStep30 from "../assets/add-token-instructions/azure/30.png";
import AzureStep31 from "../assets/add-token-instructions/azure/31.png";
import AzureStep32 from "../assets/add-token-instructions/azure/32.png";
import AzureStep33 from "../assets/add-token-instructions/azure/33.png";
import AzureStep34 from "../assets/add-token-instructions/azure/34.png";
import AzureStep35 from "../assets/add-token-instructions/azure/35.png";
import AzureStep36 from "../assets/add-token-instructions/azure/36.png";

import GcpStep1 from "../assets/add-token-instructions/gcp/1.png";
import GcpStep2 from "../assets/add-token-instructions/gcp/2.png";
import GcpStep3 from "../assets/add-token-instructions/gcp/3.png";
import GcpStep4 from "../assets/add-token-instructions/gcp/4.png";
import GcpStep5 from "../assets/add-token-instructions/gcp/5.png";
import GcpStep6 from "../assets/add-token-instructions/gcp/6.png";
import GcpStep7 from "../assets/add-token-instructions/gcp/7.png";
import GcpStep8 from "../assets/add-token-instructions/gcp/8.png";
import GcpStep9 from "../assets/add-token-instructions/gcp/9.png";
import GcpStep10 from "../assets/add-token-instructions/gcp/10.png";
import GcpStep11 from "../assets/add-token-instructions/gcp/11.png";
import GcpStep12 from "../assets/add-token-instructions/gcp/12.png";
import GcpStep13 from "../assets/add-token-instructions/gcp/13.png";
import GcpStep14 from "../assets/add-token-instructions/gcp/14.png";
import GcpStep15 from "../assets/add-token-instructions/gcp/15.png";
import GcpStep16 from "../assets/add-token-instructions/gcp/16.png";
import GcpStep17 from "../assets/add-token-instructions/gcp/17.png";

import OciStep1 from "../assets/add-token-instructions/oci/01.png";
import OciStep2 from "../assets/add-token-instructions/oci/02.png";
import OciStep3 from "../assets/add-token-instructions/oci/03.png";
import OciStep4 from "../assets/add-token-instructions/oci/04.png";
import OciStep5 from "../assets/add-token-instructions/oci/05.png";

// Single place to define the name of the product
// Tomorrow the name changes, branding or styling changes
// Change here and that should be all.
export const USER_FACING_PRODUCT_NAME = "CloudNudge";

export const CLOUD_PROVIDER_AWS = "aws";
export const CLOUD_PROVIDER_GCP = "gcp";
export const CLOUD_PROVIDER_AZURE = "azure";
export const CLOUD_PROVIDER_OCI = "oci";
export const CLOUD_PROVIDER_ALL = "total";
export const CLOUD_PROVIDER_DEFAULT = CLOUD_PROVIDER_ALL;

export const CLOUD_PROVIDER_LIST = [
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
];

export const TREND_UPWARD = "upward";
export const TREND_DOWNWARD = "downward";
export const TREND_STABLE = "stable";

export const DURATION_KEY_LAST7DAYS = "last7Days";
export const DURATION_KEY_LASTMONTH = "last30Days";
export const DURATION_KEY_LASTQUARTER = "last3Months";
export const DURATION_KEY_LASTYEAR = "lastYear";

export const STANDARD_DURATIONS = [
  {
    duration: DURATION_KEY_LAST7DAYS,
    label: "7 Days",
  },
  {
    duration: DURATION_KEY_LASTMONTH,
    label: "30 Days",
  },
  {
    duration: DURATION_KEY_LASTQUARTER,
    label: "3 Months",
  },
  {
    duration: DURATION_KEY_LASTYEAR,
    label: "1 Year",
  },
];

// local storage related keys. Compulsorily window.localStorage.
// When adding a new key here, please bear in mind if that associated value also
// needs to be deleted on logout. If yes, also do the needful at appropriate logout()
// implementation
export const STORAGE_AUTH_TOKEN = "token";
export const STORAGE_USER_INFO = "user_info";
export const STORAGE_HAS_SHOWN_GUIDED_TOUR = "has_shown_guided_tour";

// local caching related keys.
// This also includes caching done by tanstack-react-query
export const QUERY_KEY_RECOMMENDATIONS = "recommendations";
export const QUERY_KEY_ACCOUNT_DETAILS = "account-details";
export const QUERY_KEY_ACCOUNT_SUMMARY = "account-summary";
export const QUERY_KEY_ACC_TOKENS = "account-tokens";
export const QUERY_KEY_ACCOUNT_SPEND_EFFECIENCY = "account-spend-effeciency";
export const QUERY_KEY_RECOMMENDATION_FILTERS = "recommendations-filters";
export const QUERY_KEY_COST_BY_REGION = "cost-by-region";
export const QUERY_KEY_TOP_ACC_COMPUTE_EFFECIENCY = "top-compute-effeciency";
export const QUERY_KEY_ACCOUNT_COMPUTE_EFFECIENCY =
  "account-compute-effeciency";
export const QUERY_KEY_POSSIBLE_SAVINGS = "possible-savings";
export const QUERY_KEY_MONITORING_CPU = "recommendations-monitoring-cpu";
export const QUERY_KEY_MONITORING_MEMORY = "recommendations-monitoring-memory";

// types of recommendations
export const RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS = "terminate";
export const RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE = "downsize";
export const RECOMMENDATION_TYPE_CHANGING_ARCH = "architecture";
export const RECOMMENDATION_TYPE_OPTIMAL = "optimal";
// type 5 is coming soon...

export const STANDARD_RECOMMENDATION_TYPES = [
  {
    type: RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS,
    label: "By Terminating idle VMs",
  },
  {
    type: RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE,
    label: "By Downsizing idle VMs",
  },
  {
    type: RECOMMENDATION_TYPE_CHANGING_ARCH,
    label: "By Changing Architecture",
  },
];

export const ADDITIONAL_RECOMMENDATION_TYPE = "all";

export const STANDARD_RECOMMENDATION_TYPES_V2 = [
  RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS,
  RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE,
  RECOMMENDATION_TYPE_CHANGING_ARCH,
  ADDITIONAL_RECOMMENDATION_TYPE,
];

export const RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS_V2 = "Terminate";
export const RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE_V2 = "Downsize";
export const RECOMMENDATION_TYPE_CHANGING_ARCH_V2 = "Architecture";
export const RECOMMENDATION_TYPE_IDLE_V2 = "Idle";
export const RECOMMENDATION_TYPE_OPTIMAL_V2 = "Optimal";
export const QUERY_KEY_RECOMMENDATION_DROPDOWN_OPTIONS =
  "recommendation-dropdown-options";

export const RECOMMENDATION_TAGS = {
  same_architecture: "Same Architecture",
  cross_architecture: "Cross Architecture",
  parallel_architecture: "Parallel Architecture",
};

export const QUERY_KEY_COST_BY_ACCOUNT = "cost-by-account";

export const TOKEN_INSTRUCTIONS_DATA = {
  aws: {
    images: [
      AwsStep1,
      AwsStep2,
      AwsStep3,
      AwsStep4,
      AwsStep5,
      AwsStep6,
      AwsStep7,
      AwsStep8,
      AwsStep9,
      AwsStep10,
      AwsStep11,
      AwsStep12,
      AwsStep13,
      AwsStep14,
      AwsStep15,
    ],
    instructions: [
      [
        "Log in to the AWS Management Console.",
        "First, create a permission policy for the tool. To do this, navigate to the IAM (Identity and Access Management) dashboard.",
      ],
      ["In the IAM dashboard, locate and click on 'Policies'."],
      ["Create a new policy by selecting Create 'policy'."],
      ["Switch to JSON View"],
      [
        {
          type: "html",
          value: (
            <>
              Replace the existing JSON content with the following and Click the{" "}
              <b>Next</b> button:
            </>
          ),
        },
        {
          type: "json",
          value: `{
"Version": "2012-10-17",
"Statement": [
{
"Sid": "VisualEditor0",
"Effect": "Allow",
"Action": [
"sts:AssumeRole",
"ec2:DescribeInstances",
"ec2:DescribeInstanceTypeOfferings",
"ec2:DescribeInstanceTypes",
"ec2:ModifyInstanceAttribute",
"ce:GetCostAndUsage",
"organizations:ListAccounts",
"cloudwatch:GetMetricStatistics",
"ec2:DescribeInstanceStatus",
"iam:GetPolicyVersion",
"iam:GetPolicy",
"iam:ListAttachedUserPolicies",
"iam:ListAccessKeys",
"cloudwatch:ListMetrics"
],
"Resource": "*"
}
]
}`,
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Enter a <b>Policy Name</b> and optional <b>Description</b> for the
              policy.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>Create policy.</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Navigate to the <b>Users</b> section in IAM.
            </>
          ),
        },
      ],
      ["Select the user you want to grant permissions to."],
      [
        {
          type: "html",
          value: (
            <>
              Click <b>Add permissions.</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Choose <b>Attach policies directly.</b>
            </>
          ),
        },
      ],
      [
        "Search for and select the policy you just created or updated.",
        {
          type: "html",
          value: (
            <>
              Click <b>Next</b> and then <b>Add permissions</b>
            </>
          ),
        },
      ],
      [
        "After attaching the necessary policy to the user, follow these steps to create a secret key:",
        {
          type: "html",
          value: (
            <>
              Go to the <b>IAM</b> service in the AWS Management Console.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Select <b>Users</b> from the left-hand menu.
            </>
          ),
        },
        "Click on the user to whom you've just added the permissions.",
        {
          type: "html",
          value: (
            <>
              On the user’s details page, go to the <b>Security credentials</b>{" "}
              tab.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Scroll down to the <b>Access keys</b> section.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Click <b>Create access key</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              select the <b>Application running on an AWS compute service</b>{" "}
              option.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>Next</b> to proceed
            </>
          ),
        },
        "Description tag is optional",
        {
          type: "html",
          value: (
            <>
              Click <b>Create access key</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              AWS will generate an <b>Access Key ID</b> and a{" "}
              <b>Secret Access Key.</b>"
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              <b>Important:</b> Download or copy the secret key immediately, as
              it will not be shown again.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>Download .csv file</b> for safekeeping, or manually note
              down the details.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>Done</b> to complete the process.
            </>
          ),
        },
      ],
    ],
  },
  azure: {
    images: [
      AzureStep1,
      AzureStep2,
      AzureStep3,
      AzureStep4,
      AzureStep5,
      AzureStep6,
      AzureStep7,
      AzureStep8,
      AzureStep9,
      AzureStep10,
      AzureStep11,
      AzureStep12,
      AzureStep13,
      AzureStep14,
      AzureStep15,
      AzureStep16,
      AzureStep17,
      AzureStep18,
      AzureStep19,
      AzureStep20,
      AzureStep21,
      AzureStep22,
      AzureStep23,
      AzureStep24,
      AzureStep25,
      AzureStep26,
      AzureStep27,
      AzureStep28,
      AzureStep29,
      AzureStep30,
      AzureStep31,
      AzureStep32,
      AzureStep33,
      AzureStep34,
      AzureStep35,
      AzureStep36,
    ],
    instructions: [
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  Create a Key Vault:
                  <ul>
                    <li>Search for "key vault" in the search bar.</li>
                    <li>Click on "Key Vault" from the search results</li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      ["Click on the 'Create' button."],
      [
        "Choose the resource group.",
        "Provide a name for the key vault.",
        `Click on "Review and Create," then wait for the deployment to finish.`,
      ],
      [
        "After deployment, go to resources to verify that the key vault is created.",
      ],
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  Grant Permissions to Key Vault:
                  <ul>
                    <li>Navigate to the Key Vault.</li>
                    <li>Click on "IAM."</li>
                    <li>Click on "Add Role Assignment."</li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      [`Add the "Key Vault Administrator" role.`, "Click on Next."],
      [
        {
          type: "html",
          value: (
            <>
              Click <b>"Select members".</b>
            </>
          ),
        },
        "Search for your user account.",
        {
          type: "html",
          value: (
            <>
              Click <b>"Next".</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              <b>Click "Review + Assign" to complete the role assignment.</b>
            </>
          ),
        },
      ],
      [`Navigate to "Secrets."`, `Click on "Generate/Import."`],
      [`Provide a name and secret value, then click "Create."`],
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  Create a Service Principal:
                  <ul>
                    <li>Go to the Azure Active Directory.</li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      [`Click on "App Registrations."`],
      [`Click on "New registration.`],
      [`Provide a name for the service principal, then register it.`],
      [`Click on "Certificate or Secret" to create a new client secret.`],
      [`Click on New Client secret.`],
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  Copy the value of the client secret.
                  <ul>
                    <li>
                      Once the client secret is created, the <b>value</b> will
                      be displayed <b>only once.</b>
                    </li>
                    <li>
                      <b>Copy</b> the value immediately and store it securely in
                      a password manager or secure storage.
                    </li>
                    <li>
                      This value is your <b>Client Secret</b> and will be
                      required for authentication.
                    </li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  Grant Access to Service Principal:
                  <ul>
                    <li>Go back to the Key Vault</li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      [
        `Click on the key vault created earlier.`,
        `Navigate to "IAM" and click "Add Role Assignment."`,
      ],
      [`Add the "Key Vault Secrets User" role.`, "Click on Next."],
      [
        `Select members (Select the service principal created earlier), review, and
assign permissions`,
      ],
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ul>
                <li>
                  Grant Role to Service Principal in Resource Group:
                  <ul>
                    <li>Go to the Resource Group.</li>
                  </ul>
                </li>
              </ul>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Navigate to the <b>Resource Group</b> where you want to grant
              access.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Click on <b>"Access Control (IAM)"</b> in the left-hand menu.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Select <b>"Add Role Assignment".</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Search for and select the <b>"Reader"</b> role.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>"Next"</b> to proceed.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Under the <b>Members</b> section, click <b>"Select Members".</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Search for and select the <b>Service Principal</b> to which you
              want to assign the role.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>"Select"</b> and then <b>"Next"</b>.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Review the selected <b>Role</b> and <b>Member(s).</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>"Review + Assign"</b> to complete the process.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  Retrieve Keys for the Service Principal:
                  <ul>
                    <li>Go to Azure Active Directory.</li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      [`Navigate to "App Registrations" and view all applications.`],
      [`Select the service principal created earlier.`],
      [
        {
          type: "html",
          value: (
            <>
              Here, you can view the <b>Client ID</b> and <b>Tenant ID</b>
            </>
          ),
        },
      ],
      [
        `Open the Azure portal.`,
        `Search for Cost Management + Billing in the search bar.`,
      ],
      [`Select Cost Management from the left panel.`],
      [`Select Access Control (IAM).`, `Click on Add Role Assignment.`],
      [
        `Select the Reader role from the list of available roles.`,
        `Click Next.`,
      ],
      [
        {
          type: "html",
          value: (
            <>
              Under the <b> Members </b> section, click <b>"Select Members".</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Search for and select the <b> Service Principal </b> to which you
              want to assign the role.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b> "Select" </b> and then <b>"Next".</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Review the <b> selected Role </b> and <b>Member(s).</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b> "Review + Assign" </b> to complete the process.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  To find the subscription ID:
                  <ul>
                    <li>Go to the Key Vault.</li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      [
        `Inside the Key Vault, you'll find the subscription ID.`,
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ul>
                <li>
                  Now You Have the Required Keys:
                  <ul>
                    <li>
                      <b>Client ID:</b> The application (service principal) ID
                      of your App Registration.
                    </li>
                    <li>
                      <b>Tenant ID:</b>The directory (tenant) ID associated with
                      your Azure Active Directory.
                    </li>
                    <li>
                      <b>Client Secret:</b> The client secret value generated
                      earlier.
                    </li>
                    <li>
                      <b>Subscription ID:</b> The subscription under which your
                      resources are managed.
                    </li>
                    <li>
                      <b>Resource Group Name:</b> The name of the resource group
                      to manage resources.
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          ),
        },
      ],
    ],
  },
  gcp: {
    images: [
      GcpStep1,
      GcpStep2,
      GcpStep3,
      GcpStep4,
      GcpStep5,
      GcpStep6,
      GcpStep7,
      GcpStep8,
      GcpStep9,
      GcpStep10,
      GcpStep11,
      GcpStep12,
      GcpStep13,
      GcpStep14,
      GcpStep15,
      GcpStep16,
      GcpStep17,
    ],
    instructions: [
      [
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>
                  Create a Service Account
                  <ul>
                    <li>
                      <b>Open the Google Cloud Console</b>
                    </li>
                    <li>Go to the Google Cloud Console.</li>
                    <li>
                      In the Cloud Console, navigate to{" "}
                      <b>IAM & Admin &gt; Service Accounts.</b>
                    </li>
                  </ul>
                </li>
              </ol>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Click the <b>+ Create Service Account</b> button.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              <b>Service Account Name:</b> Enter a name for the service account
              (e.g., cloudnudge-service-account).
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              <b>Service Account ID:</b> This will auto-generate based on the
              name you provide, but you can edit it if necessary.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              <b>Description:</b> Optionally, provide a description for the
              service account (e.g., "Service account for CloudNudge API
              access")
            </>
          ),
        },
        "Click on Done",
      ],
      [
        "After creating the service account, click on the service account you just created from the list.",
        {
          type: "html",
          value: (
            <>
              On the right side of the service account details page, click the
              three-dot menu (...) next to the <b>Service Account</b> name and
              select <b>Manage Keys.</b> This will redirect you to the{" "}
              <b>Keys</b> tab.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              In the <b>Keys</b> tab, click the <b>Add Key</b> button and select{" "}
              <b>Create new key.</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Choose the <b>JSON</b> key type and click <b>Create.</b>
            </>
          ),
        },
        "This file is important for authentication during the onboarding process.",
      ],
      [
        {
          type: "html",
          value: (
            <>
              Click on <b>IAM</b> in the left sidebar.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              In the IAM page, click on <b>Grant Access.</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              This will open a <b>Add Principal</b> panel to allow you to grant
              specific roles to users or service accounts.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              <b>In the "New principal" section,</b> add the{" "}
              <b>service account</b> that you created earlier (this will be
              listed by its email, e.g.,
              <code>
                your-service-account@your-project-id.iam.gserviceaccount.com).
              </code>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              In the <b>Role</b> section, click on <b>Select a role</b> and
              choose Basic &gt; Viewer.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              The <b>Viewer</b> role allows the service account to view
              resources within the project but not modify them.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              In the Google Cloud Console, go to the <b>Billing</b> section.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              You can do this by selecting the <b>hamburger menu</b> (three
              horizontal lines) in the top left corner of the Cloud Console, and
              then selecting <b>Billing</b> from the menu.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              <b>Select the Appropriate Billing Account:</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              In the <b>Billing</b> section, choose the billing account linked
              to your project.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              In the <b>Billing export</b> section, you will see options for
              <b>Standard usage cost</b> and <b>Detailed usage cost.</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Find the <b>Standard usage cost</b> section and click{" "}
              <b>Edit settings.</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              In the <b>Destination project</b> dropdown, select the project
              where the billing export data will be stored.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Specify the <b>BigQuery dataset</b> where the billing export data
              for <b>Standard usage cost</b> will be written.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Once the project and database are selected, click <b>Save</b> to
              confirm the configuration.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Find the <b>Detailed usage cost</b> section and click{" "}
              <b>Edit settings.</b>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Choose the appropriate <b>Destination project</b> from the
              dropdown.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              In the <b>BigQuery dataset</b> field, select the database where
              the billing export data for <b>Detailed usage cost</b> will be
              stored.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              After setting the project and database, click <b>Save</b> to
              finalize the configuration.
            </>
          ),
        },
      ],
      ["Click on the Dataset Name."],
      [
        {
          type: "html",
          value: (
            <>
              In the <b>Dataset details</b> page, copy both the{" "}
              <b>Dataset ID</b> and the <b>Table ID.</b> These will be needed
              during the onboarding process.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Then click on the <b>Sharing</b> tab to assign billing permission
              for service principle.
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Click <b>Add Principal.</b>
            </>
          ),
        },
      ],
      [
        {
          type: "html",
          value: (
            <>
              Enter the email address of the service account you created earlier{" "}
              <code>
                (e.g.,
                your-service-account@your-project-id.iam.gserviceaccount.com).
              </code>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Select <b>BigQuery Data Editor</b> role to allow the service
              account to view billing data in the dataset.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>Save</b> to apply the permissions.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click <b>Save</b> to apply the permissions.
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              <b>Gather Required Information for Onboarding</b>
              <ul>
                <li>
                  Ensure you have already downloaded the JSON key file when
                  creating the service account.
                </li>
                <li>
                  This file is critical for authentication during the onboarding
                  process.
                </li>
              </ul>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              <b>Get the Project ID:</b>
              <ul>
                <li>
                  In the Google Cloud Console, navigate to the Home page or the
                  Project Settings page.
                </li>
                <li>
                  Locate and copy the Project ID of the project associated with
                  the service account and billing export.
                </li>
              </ul>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              <b>
                Confirm that you have the following details ready for
                onboarding:
              </b>
              <ul>
                <li>Service Account JSON Key (File)</li>
                <li>Project ID</li>
                <li>Dataset ID</li>
                <li>Table ID</li>
              </ul>
            </>
          ),
        },
      ],
    ],
  },
  oci: {
    images: [OciStep1, OciStep2, OciStep3, OciStep4, OciStep5],
    instructions: [
      [
        {
          type: "html",
          value: (
            <>
              Login to{" "}
              <a href="https://cloud.oracle.com/" target="_blank">
                https://cloud.oracle.com/
              </a>
            </>
          ),
        },
        {
          type: "html",
          value: (
            <>
              Click on profile Icon and then{" "}
              <b>Identity Domain: Default option Login</b>
            </>
          ),
        },
      ],
      [
        "Click on Users",
        "Select the user from whom you want to create the key credentials",
      ],
      ["Click on option “API Keys”", "Then click on button “Add API Key”"],
      [
        "Click button “Download Private key” and download the pem file",
        "Click on add button",
      ],
      [
        "After clicking on “Add” button",
        "You will see the “Configuration file preview” pop up window",
        "Click on “Copy”",
        "Paste the content on the Notepad and save without extension, this will be your config file",
        {
          type: "html",
          hideNumber: true,
          value: (
            <>
              <ol>
                <li>Upload the pem file</li>
                <li>Upload config file</li>
                <li>Give key label</li>
              </ol>
            </>
          ),
        },
      ],
    ],
  },
};

export const COST_SAVING_OPTIONS = [
  {
    label: "Aggressive",
    value: "aggressive",
  },
  {
    label: "Average",
    value: "average",
  },
  {
    label: "Defensive",
    value: "defensive",
  },
];

export const INFO_CLOUDNUDGE_EMAIL = "info@cloudnudge.com";
