import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { fetch, store, destroy, update } from "../utils/httpUtil";
import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";
import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { QUERY_KEY } from "../utils/queryKeys";
import { CLOUD_PROVIDER_GCP, CLOUD_PROVIDER_OCI } from "../utils/constants";

const getTokenList = async (cloudType) => {
  try {
    const endpoints = {
      aws: `cloud/aws-creds/`,
      azure: `cloud/azure-creds/`,
      gcp: `cloud/gcp-creds/`,
      oci: `cloud/oci-creds/`,
    };

    const res = await fetch(endpoints[cloudType]);
    return res?.data ?? [];
  } catch (err) {
    const parsedError = parseNetworkErrorV2(err.response);
    throw new Error(parsedError);
  }
};

const insertToken = async (cloudType, tokenData) => {
  try {
    let formSubmittionData = tokenData;
    let isMultipart = false;
    const formData = new FormData();
    if (cloudType === CLOUD_PROVIDER_GCP || cloudType === CLOUD_PROVIDER_OCI) {
      for (let key in tokenData) {
        if (tokenData[key] instanceof File) {
          formData.append(key, tokenData[key], tokenData[key].name);
          isMultipart = true;
        } else {
          formData.append(key, tokenData[key]);
        }
      }
      formSubmittionData = formData;
    }

    const res = await store(
      `cloud/${cloudType}-creds/`,
      formSubmittionData,
      isMultipart
    );
    return res;
  } catch (err) {
    const parsedError = parseNetworkErrorV2(err.response);
    throw new Error(parsedError);
  }
};

const updateToken = async (cloudType, tokenId, tokenData) => {
  try {
    let formSubmittionData = tokenData;
    let isMultipart = false;
    const formData = new FormData();
    if (cloudType === CLOUD_PROVIDER_GCP || cloudType === CLOUD_PROVIDER_OCI) {
      for (let key in tokenData) {
        if (tokenData[key] instanceof File) {
          formData.append(key, tokenData[key], tokenData[key].name);
          isMultipart = true;
        } else {
          formData.append(key, tokenData[key]);
        }
      }
      formSubmittionData = formData;
    }
    const endpoint = `cloud/${cloudType}-creds/${tokenId}/`;
    const resp = await update(endpoint, formSubmittionData, isMultipart);
    return resp;
  } catch (err) {
    const parsedError = parseNetworkErrorV2(err.response);
    throw new Error(parsedError);
  }
};

const deleteToken = async (cloudType, tokenId) => {
  try {
    const endpoint = `cloud/${cloudType}-creds/${tokenId}/`;
    const resp = await destroy(endpoint);
    return resp;
  } catch (err) {
    const parsedError = parseNetworkErrorV2(err.response);
    throw new Error(parsedError);
  }
};

export function useCloudProviderTokenV2(cloudType) {
  const queryClient = useQueryClient();

  const getTokenListV2Query = useQuery({
    queryKey: [QUERY_KEY.TOKEN_LIST_V2, cloudType],
    queryFn: async () => await getTokenList(cloudType),
    enabled: !!cloudType,
    refetchOnWindowFocus: false,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  const insertTokenMutation = useMutation({
    mutationFn: (payload) => insertToken(cloudType, payload),
    onSuccess: (data) => {
      const response = data.data;
      queryClient.setQueryData(
        [QUERY_KEY.TOKEN_LIST_V2, cloudType],
        (oldData) => {
          if (oldData) {
            return [...oldData, response];
          } else {
            return [response];
          }
        }
      );
      // This will update the cache for api that has used on dashboard and recommendations page
      queryClient.setQueryData([QUERY_KEY.CLOUD_KEYS, cloudType], (oldData) => {
        if (oldData) {
          return [
            ...oldData,
            { id: response.id, key_label: response.key_label },
          ];
        }
        return [{ id: response.id, key_label: response.key_label }];
      });

      // This will invalidate the cache for api that has used on dashboard and recommendations page
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] !== QUERY_KEY.TOKEN_LIST_V2 &&
            query.queryKey[0] !== QUERY_KEY.CLOUD_KEYS
          );
        },
      });
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  const updateTokenMutation = useMutation({
    mutationFn: (payload) =>
      updateToken(cloudType, payload.tokenId, payload.tokenData),
    onSuccess: (data) => {
      const response = data.data;
      queryClient.setQueryData(
        [QUERY_KEY.TOKEN_LIST_V2, cloudType],
        (oldData) => {
          if (oldData) {
            return oldData.map((item) => {
              if (item.id === response.id) {
                return response;
              }
              return item;
            });
          }
          return [response];
        }
      );
      // This will update the cache for api that has used on dashboard and recommendations page
      queryClient.setQueryData([QUERY_KEY.CLOUD_KEYS, cloudType], (oldData) => {
        if (oldData) {
          return oldData.map((item) => {
            if (item.id === response.id) {
              return { id: response.id, key_label: response.key_label };
            }
            return item;
          });
        }
        return [{ id: response.id, key_label: response.key_label }];
      });

      // This will invalidate the cache for api that has used on dashboard and recommendations page
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] !== QUERY_KEY.TOKEN_LIST_V2 &&
            query.queryKey[0] !== QUERY_KEY.CLOUD_KEYS
          );
        },
      });
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  const deleteTokenMutation = useMutation({
    mutationFn: (tokenId) => deleteToken(cloudType, tokenId),
    onSuccess: (_, variables) => {
      const tokenIdThatHasDeleted = variables;
      queryClient.setQueryData(
        [QUERY_KEY.TOKEN_LIST_V2, cloudType],
        (oldData) => {
          if (oldData) {
            return oldData.filter((item) => item.id !== tokenIdThatHasDeleted);
          } else {
            return [];
          }
        }
      );
      // This will update the cache for api that has used on dashboard and recommendations page
      queryClient.setQueryData([QUERY_KEY.CLOUD_KEYS, cloudType], (oldData) => {
        if (oldData) {
          return oldData.filter((item) => item.id !== tokenIdThatHasDeleted);
        }
        return [];
      });

      // This will invalidate the cache for api that has used on dashboard and recommendations page
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] !== QUERY_KEY.TOKEN_LIST_V2 &&
            query.queryKey[0] !== QUERY_KEY.CLOUD_KEYS
          );
        },
      });
    },
    onError: (err) => {
      const error = parseNetworkErrorV2(err.response);
      throw new Error(error);
    },
  });

  return {
    getTokenListV2Query,
    insertTokenMutation,
    deleteTokenMutation,
    updateTokenMutation,
  };
}
