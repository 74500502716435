import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import InputField from "../InputField/InputField";
import { TOKEN_MODAL_TYPES } from "../addCloudProvider/AddCloudProviderV2";
import {
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_OCI,
} from "../../utils/constants";
import { useCloudProviderTokenV2 } from "../../hooks/useCloudProviderTokenV2";

const FORM_FIELDS = {
  [CLOUD_PROVIDER_AZURE]: {
    key_label: "",
    tenant_id: "",
    client_id: "",
    client_secret: "",
    subscription_id: "",
    resource_group_name: "",
  },
  [CLOUD_PROVIDER_GCP]: {
    key_label: "",
    project_id: "",
    dataset_id: "",
    table_id: "",
    credential_file: null,
  },
  [CLOUD_PROVIDER_OCI]: {
    key_label: "",
    config_file: null,
    pem_file: null,
  },
};

const renderFields = (selectCloudProvider, control) => {
  switch (selectCloudProvider) {
    case CLOUD_PROVIDER_AZURE:
      return (
        <>
          <InputField
            name="key_label"
            label="Key Label"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Key Label is required",
            }}
          />
          <InputField
            name="tenant_id"
            label="Tenant ID"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Tenant ID is required",
            }}
          />
          <InputField
            name="client_id"
            label="Client ID"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Client ID is required",
            }}
          />
          <InputField
            name="client_secret"
            label="Client Secret"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Client Secret is required",
            }}
          />
          <InputField
            name="subscription_id"
            label="Subscription ID"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Subscription ID is required",
            }}
          />
          <InputField
            name="resource_group_name"
            label="Resource Group Name"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Resource Group Name is required",
            }}
          />
        </>
      );
    case CLOUD_PROVIDER_GCP:
      return (
        <>
          <InputField
            name="key_label"
            label="Key Label"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Resource Group Name is required",
            }}
          />
          <InputField
            name="project_id"
            label="Project ID"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Project ID is required",
            }}
          />
          <InputField
            name="dataset_id"
            label="DataSet ID"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "DataSet ID is required",
            }}
          />
          <InputField
            name="table_id"
            label="Table ID"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Table ID is required",
            }}
          />
          <InputField
            name="credential_file"
            label="GCP Credentials File"
            control={control}
            customStyle={{ width: "100%" }}
            type="file"
            accept=".json"
            rules={{
              required: "GCP Credentials File is required",
              validate: (value) => {
                const acceptedFormats = ["json"];
                const fileExtension = value?.name
                  ?.split(".")
                  .pop()
                  .toLowerCase();
                if (!acceptedFormats.includes(fileExtension)) {
                  return "Invalid file format. Only json files are allowed.";
                }
                return true;
              },
            }}
          />
        </>
      );
    case CLOUD_PROVIDER_OCI:
      return (
        <>
          <InputField
            name="key_label"
            label="Key Label"
            control={control}
            fullWidth
            margin="dense"
            rules={{
              required: "Key Label is required",
            }}
          />
          <InputField
            name="config_file"
            label="Config File ID"
            control={control}
            type="file"
            customStyle={{ width: "100%" }}
            rules={{
              required: "Config File ID is required",
              validate: (value) => {
                const fileExtension = value?.type;

                if (fileExtension !== "") {
                  return "Invalid file format, please upload valid config file only";
                }
                return true;
              },
            }}
          />
          <InputField
            name="pem_file"
            label="Pem File ID"
            control={control}
            type="file"
            accept=".pem"
            customStyle={{ width: "100%" }}
            rules={{
              required: "Pem File ID is required",
              validate: (value) => {
                const acceptedFormats = ["pem"];
                const fileExtension = value?.name
                  ?.split(".")
                  .pop()
                  .toLowerCase();

                if (!acceptedFormats.includes(fileExtension)) {
                  return "Invalid file format. Only pem files are allowed.";
                }
                return true;
              },
            }}
          />
        </>
      );
    default:
      return null;
  }
};

const AddEditTokenModal = ({
  isOpen,
  actionType,
  selectedCloudProvider,
  handleModalClose,
  handleInstructionClick,
  selectedTokenData,
  showSnackbar,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    clearErrors,
    reset,
  } = useForm({
    defaultValues: FORM_FIELDS[selectedCloudProvider],
    mode: "onChange",
  });

  const { insertTokenMutation, updateTokenMutation } = useCloudProviderTokenV2(
    selectedCloudProvider
  );

  useEffect(() => {
    if (isOpen) {
      reset(() => {
        if (selectedTokenData !== null) {
          const dv = {};
          Object.keys(FORM_FIELDS[selectedCloudProvider]).forEach((key) => {
            if (key.includes("file")) {
              return (dv[key] = null);
            }
            return (dv[key] = selectedTokenData[key]);
          });

          return dv;
        }
        return FORM_FIELDS[selectedCloudProvider];
      });
    }
  }, [isOpen, reset, selectedCloudProvider, selectedTokenData]);

  const submitForm = (data) => {
    if (actionType === TOKEN_MODAL_TYPES.ADD) {
      insertTokenMutation.mutate(data, {
        onSuccess: () => {
          handleModalClose();
          showSnackbar("success", "Token added successfully");
        },
      });
    } else if (actionType === TOKEN_MODAL_TYPES.EDIT) {
      updateTokenMutation.mutate(
        { tokenId: selectedTokenData.id, tokenData: data },
        {
          onSuccess: () => {
            handleModalClose();
            showSnackbar("success", "Token updated successfully");
          },
        }
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      clearErrors();
    }
  }, [clearErrors, isOpen]);

  useEffect(() => {
    if (isOpen) {
      insertTokenMutation.reset();
      updateTokenMutation.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={handleModalClose} disableEnforceFocus>
      <DialogTitle disableTypography>
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Typography variant="h4">
              {actionType === TOKEN_MODAL_TYPES.EDIT
                ? "Edit Token"
                : "Add Token"}
            </Typography>
            {actionType === TOKEN_MODAL_TYPES.ADD ? (
              <Button
                variant="outlined"
                size="small"
                startIcon={<InfoOutlinedIcon />}
                onClick={handleInstructionClick}
              >
                Instructions
              </Button>
            ) : null}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(submitForm)}>
          {renderFields(selectedCloudProvider, control)}
          {insertTokenMutation.isError || updateTokenMutation.isError ? (
            <Box
              sx={{
                color: "red",
              }}
            >
              <Typography
                sx={{
                  my: 1,
                }}
              >
                {insertTokenMutation.error?.message ||
                  updateTokenMutation.error?.message}
              </Typography>
            </Box>
          ) : null}
          <Box
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBlock: "10px",
            }}
          >
            <Button
              variant="text"
              onClick={handleModalClose}
              disabled={
                insertTokenMutation.isPending ||
                updateTokenMutation.isPending ||
                isSubmitting
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                insertTokenMutation.isPending ||
                updateTokenMutation.isPending ||
                isSubmitting
              }
            >
              {insertTokenMutation.isPending ||
              updateTokenMutation.isPending ||
              isSubmitting ? (
                <CircularProgress size={24} />
              ) : actionType === TOKEN_MODAL_TYPES.ADD ? (
                "Save"
              ) : (
                "Update"
              )}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddEditTokenModal;
