import React, { useEffect } from "react";

import styled from "@emotion/styled/macro";
import { Box, Button, css, Drawer, keyframes, Typography } from "@mui/material";
import HalfCloudLeft from "../../assets/half_cloud_left.svg";
import HalfCloudLeftMobile from "../../assets/half_cloud_left_mobile.svg";
import HalfCloudLeftWider from "../../assets/half_cloud_left_wider.svg";
import HalfCloudRight from "../../assets/half_cloud_right.svg";
import HalfCloudRightMobile from "../../assets/half_cloud_right_mobile.svg";
import HalfCloudRightWider from "../../assets/half_cloud_right_wider.svg";
import PlaneCloudRight from "../../assets/png/plane_cloud_right.png";
import PlaneCloudLeft from "../../assets/png/plane_cloud_left.png";
import { debugColor } from "../../debugTools/debugColors";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import { APP_BAR_HEIGHT, AppBar } from "../../ui/AppBar";
import DrawerBody from "./DrawerBody";
import FeatureSection from "./FeatureSection";
import Footer from "./Footer";
import ForWhoSection from "./ForWhoSection";
import HeroSection from "./HeroSection";
import MenuSection from "./MenuSection";
import PricingSection from "./PricingSection";
import WhyUsSection from "./WhyUsSection";
import CloudContainer from "./CloudContainer";
import SignUpStepsSection from "./SignUpStepsSection";
import USPSection from "./USPSection";
import WhyWeAreRealDealSection from "./WhyWeAreRealDealSection";
import { Place } from "@material-ui/icons";

const drawerWidth = 246;

export const MenuText = styled(Typography)`
  color: #3a3a3a;
  /* font-family: var(--Title-Large-Font, Roboto); */
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  /* line-height: var(--Title-Large-Line-Height, 28px); */
  /* letter-spacing: var(--Title-Large-Tracking, 0px); */
`;

export const TagLineText = styled(Typography)`
  color: #3a3a3a;
  font-feature-settings: "liga" off, "clig" off;

  /* typography/h4 */
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

export const HeaderText = styled(Typography)`
  color: #3a3a3a;
  /* font-family: var(--Title-Large-Font, Roboto); */
  font-style: normal;
  font-weight: 800;
  text-align: center;
`;

export const GradeintTextStyle = {
  background: "linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

export const GradeintText = styled(HeaderText)`
  background: linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SimpleButton = styled(Button)`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--1, 8px);
  box-shadow: 2px 2px 10px 0px rgba(230, 230, 230, 0.2);

  border-radius: 4px;
  border: 2px solid #2196f3;
  background: #ffffff;

  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  color: #2196f3;
`;

export const GradientButton = styled(SimpleButton)`
  background: linear-gradient(90deg, #2196f3 0%, #10ccd2 100%);
  border: 0px;
  color: #ffffff;
`;

export const DIVIDER_STYLE = {
  height: "2px",
  background: "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
  width: "80%",
};

export const DOM_ID_FEATURE = "#features";
export const DOM_ID_WHY_US = "#why-us";
export const ROUTE_PRICING = "/pricing";

const DOM_ID_FOR_WHO = "#for-who";
const DOM_ID_PRICING = "#pricing";

export const EMAIL_CONTACT = "info@whileone.in";
export const EMAIL_BOOK_DEMO = "info@whileone.in";

export const MENU_ITEMS = [
  {
    name: "SOLUTIONS",
    route: DOM_ID_FEATURE,
  },
  {
    name: "WHY US",
    route: DOM_ID_WHY_US,
  },
  {
    name: "PRICING",
    route: ROUTE_PRICING,
  },
  {
    name: "RESOURCES",
    route: "",
    subMenu: [
      {
        name: "DOCS",
        route: "/resources/docs",
      },
    ],
  },
  {
    name: "ABOUT US",
    route: "/about-us",
  },
];

const scrollToId = (idWithHash) => {
  // TODO: change this as its an
  // anti pattern in react.
  const section = document.getElementById(idWithHash);
  section.scrollIntoView({ behavior: "smooth" });
};

/**
 * This page is refraining from using the custom components that we have used
 * elsewhere in the project. This design(and probably the philosophy) was different
 * than the rest of the product, hence so.
 * @returns
 */
export default function LandingPage() {
  const breakpoint = useBreakpoint();
  const params = new URL(window.location.href).hash;

  useEffect(() => {
    if (params) {
      scrollToId(params);
    }
  }, [params]);

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box id="topone" position="relative">
      <AppBar toggleDrawer={toggleDrawer(!open)} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "#FFFFFF",
        }}
      >
        <Drawer
          open={open}
          onClose={toggleDrawer(false)}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <DrawerBody open={open} setOpen={setOpen} />
        </Drawer>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              mobile: "row",
              tablet: "column",
              laptop: "row",
              desktop: "row",
            },
            justifyContent: "space-between",
            mt: `${APP_BAR_HEIGHT}px`,
            height: {
              mobile: "94vh",
              tablet: "94vh",
              laptop: "94vh",
              desktop: "94vh",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              alignSelf: {
                mobile: "end",
                tablet: "end",
                laptop: "end",
                desktop: "end",
              },
              height: {
                mobile: "158px",
                tablet: "230px",
                laptop: "400px",
                desktop: "551px",
              },
              mt: {
                /* mobile: "8px",
               tablet: "230px",
              laptop: "400px",
              desktop: "551px", */
              },
            }}
            component="img"
            src={
              breakpoint === "desktop" || breakpoint === "laptop"
                ? HalfCloudRight
                : breakpoint === "mobile"
                ? HalfCloudRightMobile
                : HalfCloudLeftWider
            }
          />
          <Box
            sx={{
              background: debugColor("red"),
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {/* <MenuSection
            onFeaturesClick={() => {
              scrollToId(DOM_ID_FEATURE);
              // scrollToElement();
            }}
            onForWhoClick={() => {
              scrollToId(DOM_ID_FOR_WHO);
            }}
            onPricingClick={() => {
              scrollToId(DOM_ID_PRICING);
            }}
          /> */}
            <HeroSection />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignSelf: {
                mobile: "start",
                tablet: "start",
                laptop: "start",
                desktop: "start",
              },
              justifyContent: "center",
              mt: {
                mobile: "16px",
                tablet: "0px",
                laptop: "0px",
                desktop: "0px",
              },
              height: {
                mobile: "103px",
                tablet: "266px",
                laptop: "395px",
                desktop: "545px",
              },
            }}
            component="img"
            src={
              breakpoint === "desktop" || breakpoint === "laptop"
                ? HalfCloudLeft
                : breakpoint === "mobile"
                ? HalfCloudLeftMobile
                : HalfCloudRightWider
            }
          />
        </Box>
        <Box id={DOM_ID_FEATURE}>
          <FeatureSection />
        </Box>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            height: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: {
                mobile: "158px",
                tablet: "230px",
                laptop: "400px",
                desktop: "551px",
              },
              position: "absolute",
              top: "0",
              left: "0",
            }}
            component="img"
            src={PlaneCloudRight}
          />
          <SignUpStepsSection />
          <Box
            sx={{
              height: {
                mobile: "103px",
                tablet: "266px",
                laptop: "395px",
                desktop: "545px",
              },
              position: "absolute",
              bottom: "0",
              right: "0",
            }}
            component="img"
            src={PlaneCloudLeft}
          />
        </Box>
        {/* <CloudContainer
        id={DOM_ID_FOR_WHO}
        sx={{
          height: {
            mobile: "115vh",
            tablet: "80vh",
            laptop: "100vh",
            desktop: "110vh",
          },
        }}
      >
        <WhyUsSection />
      </CloudContainer> */}
        <USPSection />
        <Box id={DOM_ID_WHY_US}>
          <WhyWeAreRealDealSection />
        </Box>
        {/* <WhyUsSection /> */}
        {/* <Box id={DOM_ID_FOR_WHO}>
        <ForWhoSection />
      </Box> */}

        {/* <Box id={DOM_ID_PRICING}>
        <PricingSection />
      </Box> */}
        {/* <CloudContainer
        id={DOM_ID_PRICING}
        sx={{
          mb: {
            mobile: "0px",
            tablet: "140px",
            laptop: "152px",
            desktop: "152px",
          },
        }}
      >
        <PricingSection />
      </CloudContainer> */}
        <Footer />
      </Box>
    </Box>
  );
}
