export const markDownList = [
  {
    title: "Getting started with cloudnudge",
    file: "Getting started with cloudnudge.md",
  },
  {
    title: "How to Sign In to CloudNudge",
    file: "How to Sign In to CloudNudge.md",
  },
  {
    title: "User Onboarding",
    file: "User Onboarding.md",
    subDocs: [
      {
        title: "Connect AWS + CloudNudge",
        file: "Connect AWS + CloudNudge.md",
      },
      {
        title: "Connect Google Cloud + CloudNudge",
        file: "Connect Google Cloud + CloudNudge.md",
      },
      {
        title: "Connect Azure + CloudNudge",
        file: "Connect Azure + CloudNudge.md",
        subDocs: [
          {
            title: "Azure OnBoarding Part 1",
            file: "Azure-OnBoarding-Part-1.md",
          },
          {
            title: "Azure OnBoarding Part 2",
            file: "Azure-OnBoarding-Part-2.md",
          },
        ],
      },
      {
        title: "Connect Oracle Cloud Infrastructure (OCI) + CloudNudge",
        file: "Connect Oracle Cloud Infrastructure (OCI) + CloudNudge.md",
      },
    ],
  },
  {
    title: "Features of CloudNudge",
    file: "Features of CloudNudge.md",
  },
  {
    title: "Account and Administration Documentation",
    file: "Account and Administration Documentation.md",
  },
];
