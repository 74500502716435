import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Typography, Button } from "@material-ui/core";
import { SolidButton } from "./SolidButton";

const DefaultActions = ({ handleSecondaryClick, handlePrimaryClick }) => {
  return (
    <>
      <Button variant="text" onClick={handleSecondaryClick}>
        Cancel
      </Button>
      <SolidButton
        onClick={handlePrimaryClick}
        variant="contained"
        color="primary"
      >
        Ok
      </SolidButton>
    </>
  );
};

const ConfirmationDialog = ({
  title,
  body,
  open,
  handleClose,
  actions = null,
  handleSecondaryClick = () => {},
  handlePrimaryClick = () => {},
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{body}</Typography>
      </DialogContent>
      <DialogActions>
        {actions ? (
          actions
        ) : (
          <DefaultActions
            handlePrimaryClick={handlePrimaryClick}
            handleSecondaryClick={handleSecondaryClick}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
