import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { List, Box, Alert } from "@mui/material";

import PublicLayout from "../common/layout/PublicLayout";
import DocViewer from "./DocViewer";
import { markDownList } from "../../markdownList";
import FileName from "./FileName";
import HeadingsList from "./HeadingsList";

const checkFileIfExists = (fileName, markDownList) => {
  for (let i = 0; i < markDownList.length; i++) {
    if (
      markDownList[i].file.trim().toLowerCase() ===
      fileName.trim().toLowerCase()
    ) {
      return markDownList[i];
    }
    if (markDownList[i].subDocs) {
      const result = checkFileIfExists(fileName, markDownList[i].subDocs);
      if (result) {
        return result;
      }
    }
  }
  return false;
};

const DocLayout = () => {
  const reactRouterDomHistory = useHistory();
  const params = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadingContent, setLoadingContent] = useState(true);
  const [textToShow, setTextToShow] = useState("## **Loading...**");
  const [errorState, setErrorState] = useState(null);

  useEffect(() => {
    setErrorState(null);
    if (!params.file) {
      setSelectedFile(markDownList[0].file);
      return;
    }

    const isFilePresent = checkFileIfExists(`${params.file}.md`, markDownList);
    if (!isFilePresent) return setErrorState("File not found");

    setSelectedFile((prev) => {
      if (prev?.split(".")[0] === params.file) {
        return prev;
      }
      return `${params.file}.md`;
    });
  }, [params]);

  useEffect(() => {
    if (selectedFile) {
      setLoadingContent(true);
      fetch(`/Docs/${selectedFile}`)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          if (params.file !== selectedFile.split(".")[0]) {
            reactRouterDomHistory.push(
              `/resources/docs/${selectedFile.split(".")[0]}`
            );
          }
          return setTextToShow(text);
        })
        .catch((err) => {
          setErrorState("Document not available");
        })
        .finally(() => {
          setLoadingContent(false);
        });
    }
  }, [selectedFile, reactRouterDomHistory, params.file]);

  const headings = useMemo(() => {
    // pick headings from the text
    // text that starts with single hash or more than one hash
    const headingsToShow = textToShow.match(/^#{1,2}\s.+/gm);
    if (headingsToShow) {
      return headingsToShow.map((heading) => {
        return {
          heading: heading.replace(/\*+/g, "").replace(/\\+/g, ""),
          id: heading
            .replace(/#+/g, "")
            .replace(/\*+/g, "")
            .replace(/\\+/g, "")
            .replace(/`/g, "")
            .trim()
            .replace(/\s+/g, "-")
            .replace(/\./g, "")
            .replace(/&/g, "")
            .replace(/!/g, "")
            .replace(/:/g, "")
            .toLowerCase(),
        };
      });
    }
    return [];
  }, [textToShow]);

  return (
    <PublicLayout>
      <Box
        sx={{
          display: "grid",
          alignItems: "flex-start",
          gridTemplateColumns: { desktop: "1fr 2fr 1fr", tablet: "1fr 3fr" },
          gap: "20px",
          margin: "70px 0 50px",
          minHeight: {
            desktop: "calc(100vh - 306px)",
            mobile: "calc(100vh - 361px)",
          },
        }}
      >
        <Box
          style={{
            position: "sticky",
            top: "70px",
          }}
        >
          <List>
            {markDownList.map((doc, index) => {
              const isSelected =
                doc.file.trim().toLowerCase() ===
                selectedFile?.trim().toLowerCase();
              return (
                <FileName
                  key={doc.index}
                  setSelectedFile={setSelectedFile}
                  doc={doc}
                  selectedFile={selectedFile}
                  isSelected={isSelected}
                />
              );
            })}
          </List>
        </Box>
        <Box overflow="hidden">
          {errorState ? (
            <Alert severity="error">{errorState}</Alert>
          ) : (
            <DocViewer markDownContent={textToShow} />
          )}
        </Box>
        <HeadingsList
          headings={headings}
          selectedFile={selectedFile}
          loadingContent={loadingContent}
        />
      </Box>
    </PublicLayout>
  );
};

export default DocLayout;
