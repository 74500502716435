import { useQuery } from "@tanstack/react-query";

import { parseNetworkErrorV2 } from "../utils/commonUtil";
import { fetch, store } from "../utils/httpUtil";
import { QUERY_KEY } from "../utils/queryKeys";
import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";

const getRegions = async (cloudType) => {
  try {
    const resp = await fetch(`/dashboard/list-regions/${cloudType}/`);
    return resp.data || [];
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error?.response);
    throw new Error(parsedError);
  }
};

const getCrossCloudRecommendations = async (
  currentCloudId,
  crossCloudId,
  crossRegionId,
  keyId
) => {
  try {
    const response = await store(`/crosscloud/recommendation/`, {
      current_cloud_id: currentCloudId,
      cross_cloud_id: crossCloudId,
      cross_region_id: crossRegionId,
      key_id: keyId,
    });
    return response.data.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const useGetRegions = (cloudType) => {
  const getRegionsQuery = useQuery({
    queryKey: [QUERY_KEY.CC_CLOUD_REGIONS, cloudType],
    queryFn: () => getRegions(cloudType),
    enabled: !!cloudType,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  return getRegionsQuery;
};

export const useCrossCloudRecommendations = (
  dropDownData,
  shouldFetch = false
) => {
  const getCrossCloudRecommendationsQuery = useQuery({
    queryKey: [QUERY_KEY.CC_RECOMMENDATIONS_RESULTS, dropDownData],
    queryFn: () =>
      getCrossCloudRecommendations(
        dropDownData.currentCloudId,
        dropDownData.crossCloudId,
        dropDownData.crossRegionId,
        dropDownData.keyId
      ),
    refetchOnWindowFocus: false,
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
    retry: 2,
    enabled: shouldFetch,
  });

  return getCrossCloudRecommendationsQuery;
};
