import React, { useState } from "react";
import { List, ListItem, Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SubDocs = ({ doc, setSelectedFile, selectedFile, isExapanded }) => {
  const [secondLevelIsExapanded, setSecondLevelIsExapanded] = useState(false);
  if (doc.subDocs?.length === 0) return null;

  return (
    <List
      key={doc.title}
      sx={{
        display: "grid",
        gridTemplateRows: isExapanded ? "1fr" : "0fr",
        padding: "8px 4px 8px 1.25rem",
        overflow: "hidden",
        py: 0,
        transition: "grid-template-rows 0.3s ease-in-out",
      }}
    >
      <Box sx={{ overflow: "hidden" }}>
        {doc.subDocs?.map((subDoc, index) => {
          const isSubDocSelected =
            subDoc.file.trim().toLowerCase() ===
            selectedFile?.trim().toLowerCase();

          return (
            <>
              <ListItem
                key={subDoc.title}
                sx={{
                  display: "block",
                  borderLeft: isSubDocSelected
                    ? "1px solid #39b2ec"
                    : "1px solid #4a4a4a",
                  p: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    padding: "8px 4px 8px 1.25rem",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    color: isSubDocSelected ? "#39b2ec" : "#4a4a4a",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      background: "#0074d040",
                      color: "black",
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFile(subDoc.file);
                  }}
                >
                  <Typography variant="subtitle2">{subDoc.title}</Typography>
                  <KeyboardArrowDownIcon
                    sx={{
                      display: subDoc.subDocs?.length > 0 ? "block" : "none",
                      transform: secondLevelIsExapanded
                        ? "rotate(0deg)"
                        : "rotate(-90deg)",
                      transition: "all 0.3s ease-in-out",
                      cursor: "pointer",
                      borderRadius: "50%",
                      fontSize: "1.2rem",
                      "&:hover": {
                        background: "#39b2ec",
                        color: "white",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSecondLevelIsExapanded(!secondLevelIsExapanded);
                    }}
                  />
                </Box>
                {subDoc.subDocs?.length > 0 && (
                  <SubDocs
                    doc={subDoc}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    isExapanded={secondLevelIsExapanded}
                    setIsExapanded={setSecondLevelIsExapanded}
                  />
                )}
              </ListItem>
            </>
          );
        })}
      </Box>
    </List>
  );
};

const FileName = ({ setSelectedFile, selectedFile, isSelected, doc }) => {
  const [isExapanded, setIsExapanded] = useState(false);
  return (
    <ListItem
      sx={{
        display: "block",
        p: 0,
      }}
      onClick={() => {
        setSelectedFile(doc.file);
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: isSelected ? "#39b2ec" : "#4a4a4a",
          cursor: "pointer",
          padding: "8px 4px 8px 1.25rem",
          borderRadius: "5px",
          "&:hover": {
            background: "#0074d040",
            color: "black",
          },
          transition: "all 0.3s ease-in-out",
        }}
      >
        <Typography variant="subtitle2">{doc.title}</Typography>
        <KeyboardArrowDownIcon
          sx={{
            display: doc.subDocs?.length > 0 ? "block" : "none",
            transform: isExapanded ? "rotate(0deg)" : "rotate(-90deg)",
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
            borderRadius: "50%",
            fontSize: "1.2rem",
            "&:hover": {
              background: "#39b2ec",
              color: "white",
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsExapanded(!isExapanded);
          }}
        />
      </Box>
      <SubDocs
        doc={doc}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        isExapanded={isExapanded}
      />
    </ListItem>
  );
};

export default FileName;
