import { FormHelperText, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

const InputField = (props) => {
  const {
    control,
    rules = {},
    defaultValue,
    name,
    label,
    type = "text",
    customStyle = {},
    ...restProps
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...(rules && Object.entries(rules).length > 0 ? { rules } : {})}
      render={({ field, fieldState: { error: fieldError } }) => {
        if (type === "file") {
          return (
            <div>
              {label && (
                <Typography
                  variant="subtitle2"
                  component="label"
                  style={{ fontWeight: "initial" }}
                >
                  {label}
                </Typography>
              )}
              <input
                type="file"
                onChange={(e) => field.onChange(e.target.files[0])}
                style={{
                  display: "block",
                  border: fieldError ? "1px solid red" : "1px solid #C4C4C4",
                  padding: "16.5px 14px",
                  ...customStyle,
                }}
                {...restProps}
              />
              {fieldError && (
                <FormHelperText error>{fieldError.message}</FormHelperText>
              )}
            </div>
          );
        }

        return (
          <TextField
            {...field}
            error={!!fieldError}
            helperText={fieldError ? fieldError.message : null}
            type={type}
            variant="outlined"
            label={label}
            {...restProps}
          />
        );
      }}
    />
  );
};

export default InputField;
