import React from "react";
import { Box, Card, CardContent } from "@mui/material";
import { Padding } from "@mui/icons-material";

const getCloudProviderCardStyle = (isSelected) => {
  return {
    border: isSelected ? `2px solid #2196F3` : "2px solid transparent",
    width: 138,
    height: 88,
    borderRadius: "16px",
  };
};

const CloudProviderCardContentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 10,
  height: "100%",
};

const CloudProviderCard = ({ logo, alt, isSelected, onClick }) => {
  return (
    <Card style={getCloudProviderCardStyle(isSelected)} onClick={onClick}>
      <CardContent style={CloudProviderCardContentStyle}>
        <Box component="img" src={logo} alt={alt} />
      </CardContent>
    </Card>
  );
};

export default CloudProviderCard;
