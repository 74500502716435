import loadable from "@loadable/component";
import React, { Fragment } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Import custom components
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import RestrictRoute from "./RestrictRoute";

import EmailVerificationResult from "../components/auth/EmailVerificationResult";
import ForgotPassword from "../components/auth/ForgotPassword";
import SetPassword from "../components/auth/SetPassword";
import MainLayout from "../components/common/layout/MainLayout";
import NotFound from "../components/error/NotFound";
import LandingPage from "../pages/landingPage/LandingPage";
import RecommendationDetailsV2 from "../components/RecommendationDetailsV2/RecommendationDetailsV2";
import CrossCloudRecommendations from "../components/CrossCloudRecommendations/CrossCloudRecommendations";
import PricingPage from "../components/PricingPage/PricingPage";
import AboutUs from "../components/AboutUs/AboutUs";
import Profile from "../components/profile";
import AddCloudProviderV2 from "../components/addCloudProvider/AddCloudProviderV2";
import DocLayout from "../components/Docs/DocLayout";

const AsyncLoginForm = loadable(() =>
  import("../containers/auth/LoginContainer")
);
const AsyncSignUpForm = loadable(() =>
  import("../containers/auth/SignUpContainer")
);
const AsyncDashboard = loadable(() =>
  import("../containers/dashboard/DashboardContainer")
);

export const ROUTE_LANDING_PAGE = "/";
export const ROUTE_PRICING = "/pricing";
export const ROUTE_ABOUT_US = "/about-us";
export const ROUTE_DOCS = "/resources/docs/:file?";

// auth related routes
export const ROUTE_LOGIN = "/login";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_VERIFY_EMAIL = "/verify-email";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";

export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_RECOMMENDATION_DETAILS = "/recommendation-details";
export const ROUTE_ADD_NEW_CLOUD_PROVIDER = "/add-cloud-provider";
export const ROUTE_ADD_NEW_CLOUD_PROVIDER_V2 = "/add-cloud-provider-v2";
export const ROUTE_CROSS_CLOUD_RECOMMENDATIONS = "/cross-cloud-recommendations";
export const ROUTE_PROFILE = "/profile";

const CanonicalTag = () => {
  const location = useLocation();
  const canonicalURL = `${window.location.origin}${location.pathname}`;
  return (
    <Helmet>
      <link rel="canonical" href={canonicalURL} />
    </Helmet>
  );
};

const Router = () => (
  <Fragment>
    <CanonicalTag />
    <Switch>
      <RestrictRoute exact path={ROUTE_LOGIN} component={AsyncLoginForm} />
      <RestrictRoute exact path={ROUTE_SIGNUP} component={AsyncSignUpForm} />
      <Route
        exact
        path={ROUTE_VERIFY_EMAIL}
        component={EmailVerificationResult}
      />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/auth/reset-password-confirm/*"
        component={SetPassword}
      />
      <PublicRoute exact path={ROUTE_LANDING_PAGE} component={LandingPage} />
      <PublicRoute exact path={ROUTE_PRICING} component={PricingPage} />
      <PublicRoute exact path={ROUTE_ABOUT_US} component={AboutUs} />
      <PublicRoute exact path={ROUTE_DOCS} component={DocLayout} />
      <PrivateRoute
        exact
        path={ROUTE_ADD_NEW_CLOUD_PROVIDER}
        layout={MainLayout}
        component={AddCloudProviderV2}
      />
      <PrivateRoute
        exact
        path={ROUTE_DASHBOARD}
        layout={MainLayout}
        component={AsyncDashboard}
      />
      <PrivateRoute
        exact
        path={ROUTE_RECOMMENDATION_DETAILS}
        layout={MainLayout}
        component={RecommendationDetailsV2}
      />
      <PrivateRoute
        exact
        path={ROUTE_CROSS_CLOUD_RECOMMENDATIONS}
        layout={MainLayout}
        component={CrossCloudRecommendations}
      />
      <PrivateRoute
        exact
        path={ROUTE_PROFILE}
        layout={MainLayout}
        component={Profile}
      />
      <Route component={NotFound} />
    </Switch>
  </Fragment>
);

export default Router;
