import React from "react";
import { Box } from "@mui/material";
import { PopupButton } from "react-calendly";

const Calendly = () => {
  return (
    <Box>
      <PopupButton
        url="https://calendly.com/cloudnudge-info"
        rootElement={document.getElementById("root")}
        text="Book a Demo"
        styles={{
          background: "linear-gradient(90deg, #2196f3 0%, #10ccd2 100%)",
          border: "0px",
          textTransform: "uppercase",
          color: "#ffffff",
          padding: "12px 20px",
          fontWeight: 600,
          borderRadius: "4px",
          cursor: "pointer",
        }}
      />
    </Box>
  );
};

export default Calendly;
