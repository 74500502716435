import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Typography,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";
import CloudIcon from "@material-ui/icons/Cloud";
import DoneIcon from "@mui/icons-material/Done";

import AWSCloudFormation from "./AWSCloudFormation";
import TokenTable from "./TokenTable";
import AddEditTokenModal from "./AddEditTokenModal";
import AddTokenInstructionModal from "./AddTokenInstructionModal";
import {
  CLOUD_PROVIDER_LIST,
  STORAGE_HAS_SHOWN_GUIDED_TOUR,
  CLOUD_PROVIDER_AWS,
} from "../../utils/constants";
import CloudProviderCard from "../CloudProviderCard";
import { getLocalStorage, setLocalStorage } from "../../utils/storageUtil";
import { getLogoForType } from "../../utils/commonUtil";

export const TOKEN_MODAL_TYPES = {
  ADD: "add",
  EDIT: "edit",
};

const AddCloudProviderV2 = () => {
  const [hasShownGuideTour, setHasShownGuideTour] = useState(
    getLocalStorage(STORAGE_HAS_SHOWN_GUIDED_TOUR) ?? false
  );
  const [selectedCloudProvider, setSelectedCloudProvider] = useState(
    CLOUD_PROVIDER_LIST[0]
  );
  const [isAddEditTokenModalOpen, setIsAddEditTokenModalOpen] = useState({
    mode: TOKEN_MODAL_TYPES.ADD,
    value: false,
  });
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false);
  const [selectedTokenData, setSelectedTokenData] = useState(null);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState({
    value: false,
    variant: "success",
    message: "",
  });

  const handleGuidTourClose = () => {
    setHasShownGuideTour(true);
    setLocalStorage(STORAGE_HAS_SHOWN_GUIDED_TOUR, true);
  };

  const handleCloudSelect = (cloudProvider) => {
    setSelectedCloudProvider(cloudProvider);
  };

  const handleAddEditTokenModalOpen = (mode = TOKEN_MODAL_TYPES.ADD) => {
    setIsAddEditTokenModalOpen({ mode, value: true });
  };

  const handleAddEditTokenModalClose = () => {
    setIsAddEditTokenModalOpen({ mode: TOKEN_MODAL_TYPES.ADD, value: false });
    setSelectedTokenData(null);
  };

  const handleEdit = (row) => {
    handleAddEditTokenModalOpen(TOKEN_MODAL_TYPES.EDIT);
    setSelectedTokenData(row);
  };

  const showSnackbar = (variant, message) => {
    setIsOpenSnackbar({ value: true, variant, message });
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={!hasShownGuideTour}
        keepMounted
        onClose={() => handleGuidTourClose()}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            margin: "24px 24px 0 24px",
          }}
        >
          <Button variant="contained" onClick={() => handleGuidTourClose()}>
            Skip Tour
          </Button>
        </Box>
        <DialogContent>
          <div
            style={{
              position: "relative",
              boxSizing: "content-box",
              maxHeight: "80vh",
              height: "100vh",
              width: "100%",
              "aspect-ratio": 1.7777777777777777,
              // padding: "0 24px",
            }}
          >
            <iframe
              src="https://app.supademo.com/embed/cm3zpkh0o0tl210a5mspv48ud?embed_v=2"
              loading="lazy"
              title="New Demo"
              allow="clipboard-write"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          display: "flex",
          marginLeft: "9px",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <CloudIcon fontSize="large" color="primary" marginLeft="6px" />
        <Typography variant="h4" sx={{ ml: 2, left: "30px" }}>
          Choose your platform
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        {CLOUD_PROVIDER_LIST.map((cloudProvider) => (
          <CloudProviderCard
            key={cloudProvider}
            logo={getLogoForType(cloudProvider)}
            alt="AWS Logo"
            isSelected={selectedCloudProvider === cloudProvider}
            onClick={() => handleCloudSelect(cloudProvider)}
          />
        ))}
      </Box>
      <Divider sx={{ marginY: 4 }} />
      {selectedCloudProvider === CLOUD_PROVIDER_AWS ? (
        <AWSCloudFormation />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              marginLeft: "9px",
              alignItems: "center",
              my: 2,
            }}
          >
            <DoneIcon fontSize="large" color="primary" marginLeft="6px" />
            <Typography variant="h4" sx={{ ml: 2, mr: 4, left: "30px" }}>
              Optimize your cloud cost & maximize your savings today !{" "}
            </Typography>
            <Button
              sx={{
                height: "100%",
                Padding: "6px",
              }}
              variant="contained"
              color="primary"
              onClick={() => handleAddEditTokenModalOpen(TOKEN_MODAL_TYPES.ADD)}
            >
              Add Token
            </Button>
          </Box>
          <TokenTable
            selectedCloudProvider={selectedCloudProvider}
            handleAddEditTokenModalOpen={handleAddEditTokenModalOpen}
            handleEdit={handleEdit}
            showSnackbar={showSnackbar}
          />
          <AddEditTokenModal
            isOpen={isAddEditTokenModalOpen.value}
            actionType={isAddEditTokenModalOpen.mode}
            selectedCloudProvider={selectedCloudProvider}
            handleModalClose={handleAddEditTokenModalClose}
            handleInstructionClick={() => {
              setIsInstructionModalOpen(true);
            }}
            selectedTokenData={selectedTokenData}
            showSnackbar={showSnackbar}
          />
        </>
      )}
      <AddTokenInstructionModal
        instructionModalOpen={isInstructionModalOpen}
        handleClose={() => setIsInstructionModalOpen(false)}
        selectedCloudProvider={selectedCloudProvider}
      />
      <Snackbar
        open={isOpenSnackbar.value}
        autoHideDuration={6000}
        onClose={() =>
          setIsOpenSnackbar({ value: false, variant: "success", message: "" })
        }
      >
        <Alert
          onClose={() =>
            setIsOpenSnackbar({ value: false, variant: "success", message: "" })
          }
          severity={isOpenSnackbar.variant}
          variant="filled"
        >
          {isOpenSnackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddCloudProviderV2;
